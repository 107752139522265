import { login, popWebView } from '@shopee/web-bridge-sdk'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { convertAppRL, getMicrositeUrl } from 'shared/utils'
import { isShopeeApp } from 'shared/webBridge'

const LoginPage: NextPage = () => {
  const router = useRouter()
  const { query, isReady } = router

  useEffect(() => {
    if (!isShopeeApp()) router.replace('/pending')
  }, [])

  useEffect(() => {
    if (!isReady) return

    login({
      hidePopup: 1,
      redirectTab: 1,
      redirectPath: convertAppRL(getMicrositeUrl(query.site as string)),
    })

    //* If user cancel the login, pop the view to return to our microsite
    const handleReturnFromLogin = () => {
      if (document.visibilityState === 'visible') {
        popWebView({})
        document.removeEventListener('visibilitychange', handleReturnFromLogin, false)
      }
    }
    document.addEventListener('visibilitychange', handleReturnFromLogin, false)
  }, [isReady])

  return <></>
}

export default LoginPage
